package nay.kirill.miniApp.engclub.di

import nay.kirill.miniApp.engclub.navigation.Navigation
import org.koin.core.module.dsl.singleOf
import org.koin.dsl.module

val appModule =
    module {
        singleOf(::Navigation)
    }
