package nay.kirill.miniApp.engclub.theme

import androidx.compose.runtime.Composable
import androidx.compose.runtime.compositionLocalOf
import androidx.compose.ui.graphics.Color
import com.kirillNay.telegram.miniapp.compose.TelegramColors

data class EnglishClubColors internal constructor(
    val primaryColor: Color,
)

internal val defaultColors =
    EnglishClubColors(
        primaryColor = Color.Blue,
    )

internal fun appColors(telegramColors: TelegramColors) =
    defaultColors.copy(
        primaryColor = telegramColors.buttonColor,
    )

internal val LocalEnglishClubColors = compositionLocalOf { appColors(TelegramColors.fromWebApp()) }

val englishClubColors
    @Composable
    get() = LocalEnglishClubColors.current
