package nay.kirill.miniApp.engclub.features.wordDetails

import nay.kirill.miniApp.engclub.WordUI

sealed interface WordDetailsState {
    data object Loading : WordDetailsState

    data object Error : WordDetailsState

    data class Content(
        val word: WordUI,
    ) : WordDetailsState
}
