package nay.kirill.miniApp.engclub.theme

import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.kirillNay.telegram.miniapp.compose.TelegramColors
import com.kirillNay.telegram.miniapp.webApp.EventType
import com.kirillNay.telegram.miniapp.webApp.webApp

@Composable
fun EnglishClubTheme(content: @Composable () -> Unit) {
    var colors by remember { mutableStateOf(defaultColors) }
    webApp.addEventHandler(EventType.THEME_CHANGED) {
        colors = appColors(TelegramColors.fromWebApp())
    }

    CompositionLocalProvider(
        LocalTelegramTypography provides typography(),
        LocalEnglishClubColors provides colors,
        content = content,
    )
}
