package nay.kirill.miniApp.engclub.navigation

import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.material.Scaffold
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.ui.Modifier
import cafe.adriel.voyager.core.registry.ScreenRegistry
import cafe.adriel.voyager.navigator.CurrentScreen
import cafe.adriel.voyager.navigator.Navigator
import com.kirillNay.telegram.miniapp.compose.TelegramStyle
import org.koin.core.context.GlobalContext

@Composable
internal fun MainNavigationScreen(
    initData: String?,
    style: TelegramStyle,
) {
    val initialScreen =
        when {
            initData != null && initData.contains("word=") -> SharedScreens.WordDetails(initData.split("=")[1])
            else -> SharedScreens.NotFound
        }

    Navigator(ScreenRegistry.get(initialScreen)) { navigator ->
        LaunchedEffect(true) {
            GlobalContext.get().get<Navigation>().eventsStack.collect { event ->
                when (event) {
                    is Navigation.Event.Forward -> navigator.push(ScreenRegistry.get(event.screen))
                    is Navigation.Event.Back -> navigator.pop()
                }
            }
        }

        Scaffold(
            modifier =
                Modifier
                    .height(style.viewPort.viewPortHeight)
                    .fillMaxWidth(),
        ) {
            CurrentScreen()
        }
    }
}
