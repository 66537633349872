package nay.kirill.miniApp.engclub.features.notFound

import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.ui.Modifier
import cafe.adriel.voyager.core.registry.screenModule
import cafe.adriel.voyager.core.screen.Screen
import nay.kirill.miniApp.engclub.core.viewModel
import nay.kirill.miniApp.engclub.navigation.SharedScreens
import nay.kirill.miniApp.engclub.res.Strings
import nay.kirill.miniApp.engclub.uikit.FullScreenError

val notFoundScreenModule =
    screenModule {
        register<SharedScreens.NotFound> {
            NotFoundScreen()
        }
    }

private class NotFoundScreen : Screen {
    @Composable
    override fun Content() {
        val viewModel = viewModel(NotFoundViewModel::class)
        LaunchedEffect(key1 = viewModel) {
            viewModel.init()
        }

        FullScreenError(
            modifier = Modifier.fillMaxSize(),
            text = Strings.PageNotFound.page_not_found_title,
        )
    }
}
