package nay.kirill.miniApp.engclub.features.wordDetails

import com.kirillNay.telegram.miniapp.webApp.webApp
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.launch
import nay.kirill.miniApp.engclub.WordUI
import nay.kirill.miniApp.engclub.core.ViewModel
import nay.kirill.miniApp.engclub.domain.models.Word
import nay.kirill.miniApp.engclub.domain.repositories.WordRepository
import nay.kirill.miniApp.engclub.res.Strings

class WordDetailsViewModel(
    private val wordRepository: WordRepository,
) : ViewModel() {
    private val _state: MutableStateFlow<WordDetailsState> = MutableStateFlow(WordDetailsState.Loading)
    val state: StateFlow<WordDetailsState> = _state.asStateFlow()

    private var mainButtonOnError: (() -> Unit)? = null

    fun fetchWordInfo(id: String) {
        webApp.mainButton.hide()
        _state.value = WordDetailsState.Loading
        launch {
            try {
                val word =
                    wordRepository.getWord(
                        userId =
                            webApp.initDataUnsafe.user
                                ?.id
                                .toString(),
                        id = id,
                    )
                _state.value = WordDetailsState.Content(word = word.toUI())
            } catch (e: Throwable) {
                onError(wordId = id)
            }
        }
    }

    override fun onCleared() {
        super.onCleared()
        mainButtonOnError?.let(webApp.mainButton::offClick)
        webApp.mainButton.hide()
    }

    private fun onError(wordId: String) {
        webApp.mainButton.setText(Strings.WordDetails.retry_button)

        mainButtonOnError = { fetchWordInfo(wordId) }
        webApp.mainButton.onClick(mainButtonOnError!!)
        webApp.mainButton.show()

        _state.value = WordDetailsState.Error
    }

    private fun Word.toUI() =
        WordUI(
            id,
            isSaved,
            text,
            transcription,
            translation,
            image,
            definition,
            examples,
        )
}
