package nay.kirill.miniApp.engclub.uikit

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import nay.kirill.app.generated.resources.Res
import nay.kirill.app.generated.resources.error_placeholder
import nay.kirill.miniApp.engclub.theme.telegramTypography
import org.jetbrains.compose.resources.painterResource

@Composable
fun FullScreenError(
    text: String,
    modifier: Modifier = Modifier,
) {
    Column(
        modifier =
            modifier
                .padding(vertical = 26.dp),
    ) {
        Image(
            painter = painterResource(Res.drawable.error_placeholder),
            contentDescription = "Failed to get image",
            modifier =
                Modifier
                    .align(Alignment.CenterHorizontally)
                    .size(250.dp),
        )
        Spacer(modifier = Modifier.height(18.dp))
        Text(
            text = text,
            style = telegramTypography.title3Bold,
            modifier = Modifier.align(Alignment.CenterHorizontally),
        )
    }
}
