package nay.kirill.miniApp.engclub.features.notFound

import com.kirillNay.telegram.miniapp.webApp.webApp
import nay.kirill.miniApp.engclub.core.ViewModel
import nay.kirill.miniApp.engclub.res.Strings

internal class NotFoundViewModel : ViewModel() {
    private var mainButtonCallback = {
        webApp.openTelegramLink("https://t.me/EnglishClubDev")
    }

    fun init() {
        webApp.mainButton.setText(Strings.PageNotFound.page_not_found_button)
        webApp.mainButton.onClick(mainButtonCallback)
        webApp.mainButton.show()
    }

    override fun onCleared() {
        webApp.mainButton.offClick(mainButtonCallback)
        webApp.mainButton.hide()
        super.onCleared()
    }
}
