package nay.kirill.miniApp.engclub.data

import io.ktor.client.HttpClient
import io.ktor.client.engine.js.Js
import io.ktor.client.plugins.contentnegotiation.ContentNegotiation
import io.ktor.serialization.kotlinx.json.json
import kotlinx.serialization.json.Json
import kotlinx.serialization.modules.SerializersModule
import kotlinx.serialization.modules.contextual
import nay.kirill.miniApp.engclub.data.models.AlternativeTranslationsResponseSerializer
import nay.kirill.miniApp.engclub.data.models.WordResponseSerializer
import nay.kirill.miniApp.engclub.domain.repositories.WordRepository
import org.koin.dsl.module

val json =
    Json {
        serializersModule =
            SerializersModule {
                contextual(WordResponseSerializer)
                contextual(AlternativeTranslationsResponseSerializer)
            }
    }

val dataModule =
    module {
        single<WordRepository> { WordRepositoryImpl(get()) }
        single {
            HttpClient(Js) {
                install(ContentNegotiation) {
                    json(json)
                }
            }
        }
    }
