@file:OptIn(ExperimentalCoilApi::class)

package nay.kirill.miniApp.engclub

import cafe.adriel.voyager.core.registry.ScreenRegistry
import coil3.ImageLoader
import coil3.PlatformContext
import coil3.annotation.ExperimentalCoilApi
import coil3.compose.setSingletonImageLoaderFactory
import coil3.request.crossfade
import coil3.util.DebugLogger
import com.kirillNay.telegram.miniapp.compose.telegramWebApp
import com.kirillNay.telegram.miniapp.webApp.webApp
import nay.kirill.miniApp.engclub.data.dataModule
import nay.kirill.miniApp.engclub.di.appModule
import nay.kirill.miniApp.engclub.features.notFound.notFoundModule
import nay.kirill.miniApp.engclub.features.notFound.notFoundScreenModule
import nay.kirill.miniApp.engclub.features.wordDetails.wordDetailsModule
import nay.kirill.miniApp.engclub.features.wordDetails.wordDetailsScreenModule
import nay.kirill.miniApp.engclub.navigation.MainNavigationScreen
import nay.kirill.miniApp.engclub.theme.EnglishClubTheme
import org.koin.core.context.startKoin

fun main() {
    startKoin {
        modules(appModule, wordDetailsModule, notFoundModule, dataModule)
    }
    setupNavigation()

    telegramWebApp { style ->
        setSingletonImageLoaderFactory { context ->
            getAsyncImageLoader(context)
        }

        EnglishClubTheme {
            MainNavigationScreen(initData = webApp.initDataUnsafe.startParam, style = style)
        }
    }
}

private fun setupNavigation() {
    ScreenRegistry {
        wordDetailsScreenModule()
        notFoundScreenModule()
    }
}

private fun getAsyncImageLoader(context: PlatformContext) =
    ImageLoader
        .Builder(context)
        .crossfade(true)
        .logger(DebugLogger())
        .build()
