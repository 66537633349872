package nay.kirill.miniApp.engclub.features.wordDetails

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.fadeIn
import androidx.compose.animation.fadeOut
import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.CircularProgressIndicator
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.painter.ColorPainter
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.withStyle
import androidx.compose.ui.unit.dp
import cafe.adriel.voyager.core.registry.screenModule
import cafe.adriel.voyager.core.screen.Screen
import coil3.compose.AsyncImage
import nay.kirill.app.generated.resources.Res
import nay.kirill.app.generated.resources.image_placeholder
import nay.kirill.miniApp.engclub.WordUI
import nay.kirill.miniApp.engclub.core.viewModel
import nay.kirill.miniApp.engclub.navigation.SharedScreens
import nay.kirill.miniApp.engclub.res.Strings
import nay.kirill.miniApp.engclub.theme.englishClubColors
import nay.kirill.miniApp.engclub.theme.telegramTypography
import nay.kirill.miniApp.engclub.uikit.FullScreenError
import org.jetbrains.compose.resources.painterResource

val wordDetailsScreenModule =
    screenModule {
        register<SharedScreens.WordDetails> {
            WordDetailsScreen(wordId = it.wordId)
        }
    }

private class WordDetailsScreen(
    private val wordId: String,
) : Screen {
    @Composable
    override fun Content() {
        val viewModel = viewModel(WordDetailsViewModel::class)

        LaunchedEffect(key1 = viewModel) {
            viewModel.fetchWordInfo(wordId)
        }

        val state by viewModel.state.collectAsState()
        when (state) {
            is WordDetailsState.Loading -> Loading()
            is WordDetailsState.Error -> Error()
            is WordDetailsState.Content -> Content(state as WordDetailsState.Content)
        }
    }

    @Composable
    private fun Loading() {
        Box(modifier = Modifier.fillMaxSize()) {
            CircularProgressIndicator(
                modifier =
                    Modifier
                        .align(Alignment.Center)
                        .size(36.dp),
                color = englishClubColors.primaryColor,
            )
        }
    }

    @Composable
    private fun Content(state: WordDetailsState.Content) {
        Column(
            modifier =
                Modifier.padding(
                    horizontal = 26.dp,
                    vertical = 32.dp,
                ),
        ) {
            if (state.word.image != null) {
                val placeholderPainter = painterResource(Res.drawable.image_placeholder)
                WordImage(
                    imageUrl = state.word.image,
                    text = state.word.text,
                    placeholder = placeholderPainter,
                )
            }
            Spacer(modifier = Modifier.height(32.dp))
            Text(
                text = state.word.text,
                style = telegramTypography.title2Semibold,
            )

            Spacer(modifier = Modifier.height(12.dp))
            Transcription(
                translation = state.word.translation,
                transcription = state.word.transcription,
            )

            Spacer(modifier = Modifier.height(12.dp))
            Text(
                text = state.word.definition,
                style = telegramTypography.subheadline1Regular,
            )

            Spacer(modifier = Modifier.height(24.dp))
            Examples(state.word)
        }
    }

    @Composable
    private fun Examples(word: WordUI) {
        if (word.examples.isNotEmpty()) {
            Column {
                Text(
                    text = Strings.WordDetails.word_details_example,
                    style = telegramTypography.textSemibold,
                )
                Spacer(modifier = Modifier.height(8.dp))
                word.examples.forEach { example ->
                    Text(
                        text =
                            buildAnnotatedString {
                                val splitted = example.split("[${word.text}]")
                                splitted.forEachIndexed { index, span ->
                                    withStyle(telegramTypography.subheadline1Regular.toSpanStyle()) { append(span) }
                                    if (index != splitted.lastIndex) {
                                        withStyle(telegramTypography.subheadline1Bold.toSpanStyle()) { append(word.text) }
                                    }
                                }
                            },
                    )
                    Spacer(modifier = Modifier.height(6.dp))
                }
            }
        }
    }

    @Composable
    private fun Transcription(
        translation: String,
        transcription: String?,
    ) {
        Text(
            text =
                buildAnnotatedString {
                    withStyle(telegramTypography.subheadline1Regular.toSpanStyle()) { append(translation) }
                    if (transcription != null) {
                        withStyle(
                            telegramTypography.headlineBold
                                .copy(
                                    color = Color.LightGray,
                                    textIndent = null,
                                    textAlign = TextAlign.Center,
                                ).toSpanStyle(),
                        ) {
                            append(Strings.WordDetails.word_details_transcription_separator)
                        }
                        withStyle(telegramTypography.subheadline1Regular.toSpanStyle()) { append(transcription) }
                    }
                },
        )
    }

    @Composable
    private fun WordImage(
        imageUrl: String,
        text: String,
        placeholder: Painter,
    ) {
        Box(
            modifier =
                Modifier
                    .fillMaxWidth()
                    .aspectRatio(3F / 2),
        ) {
            var isError by remember { mutableStateOf(false) }

            AnimatedVisibility(
                visible = !isError,
                enter = fadeIn(),
                exit = fadeOut(),
            ) {
                AsyncImage(
                    modifier =
                        Modifier
                            .fillMaxSize()
                            .clip(shape = RoundedCornerShape(15.dp)),
                    model = imageUrl,
                    contentScale = ContentScale.FillWidth,
                    contentDescription = text,
                    placeholder = ColorPainter(Color.LightGray),
                    onError = { isError = true },
                )
            }

            AnimatedVisibility(
                visible = isError,
                enter = fadeIn(),
                exit = fadeOut(),
            ) {
                Image(
                    painter = placeholder,
                    contentDescription = "Failed load image",
                    modifier =
                        Modifier
                            .fillMaxSize()
                            .clip(shape = RoundedCornerShape(15.dp)),
                )
            }
        }
    }

    @Composable
    private fun Error() {
        FullScreenError(
            modifier = Modifier.fillMaxSize(),
            text = Strings.WordDetails.error_fetching_word,
        )
    }
}
