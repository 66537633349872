package nay.kirill.miniApp.engclub.res

object Strings {
    object WordDetails {
        val error_fetching_word = "Произошла ошибка"

        val word_details_example = "Примеры"

        val word_details_transcription_separator = " • "

        val retry_button = "Повторить"
    }

    object PageNotFound {
        val page_not_found_title = "Страница не найдена"

        val page_not_found_button = "Начать учить слова"
    }
}
