package nay.kirill.miniApp.engclub

import androidx.compose.runtime.Stable

data class WordUI(
    val id: String,
    val isSaved: Boolean,
    val text: String,
    val transcription: String?,
    val translation: String,
    val image: String?,
    val definition: String,
    @Stable val examples: List<String>,
)
