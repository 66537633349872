@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package nay.kirill.app.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.ExperimentalResourceApi

@ExperimentalResourceApi
private object Drawable0 {
  public val error_placeholder: DrawableResource = org.jetbrains.compose.resources.DrawableResource(
        "drawable:error_placeholder",
          setOf(
            org.jetbrains.compose.resources.ResourceItem(setOf(),
          "drawable/error_placeholder.webp"),
          )
      )

  public val image_placeholder: DrawableResource = org.jetbrains.compose.resources.DrawableResource(
        "drawable:image_placeholder",
          setOf(
            org.jetbrains.compose.resources.ResourceItem(setOf(),
          "drawable/image_placeholder.webp"),
          )
      )
}

@ExperimentalResourceApi
internal val Res.drawable.error_placeholder: DrawableResource
  get() = Drawable0.error_placeholder

@ExperimentalResourceApi
internal val Res.drawable.image_placeholder: DrawableResource
  get() = Drawable0.image_placeholder
