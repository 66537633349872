package nay.kirill.miniApp.engclub.domain.models

data class Word(
    val id: String,
    val isSaved: Boolean,
    val text: String,
    val transcription: String?,
    val translation: String,
    val image: String?,
    val definition: String,
    val examples: List<String>,
    val meaningsWithSimilarTranslation: List<AlternativeTranslations>,
)

data class AlternativeTranslations(
    val id: String,
    val translation: String,
)
