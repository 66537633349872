package nay.kirill.miniApp.engclub.navigation

import cafe.adriel.voyager.core.registry.ScreenProvider

sealed interface SharedScreens : ScreenProvider {
    data class WordDetails(
        val wordId: String,
    ) : SharedScreens

    data object NotFound : SharedScreens
}
